.content-about-profile-intro
{
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_profile_about_intro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center bottom;
}

.content-about-profile-outro
{
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_profile_about_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center top;
}

.content-about-profile-photo-thomas
{
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;

    width: 240px;
    height: 240px;

    background-image: url('/public/image/photo_thomas.jpg');
    background-repeat: no-repeat;
    background-size: contain;

    border-radius: 100%;
}

.content-about-profile-photo-rusmira
{
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;

    width: 240px;
    height: 240px;

    background-image: url('/public/image/photo_rusmira.jpg');
    background-repeat: no-repeat;
    background-size: contain;

    border-radius: 100%;
}

.content-about-profile-photo-dennis
{
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;

    width: 240px;
    height: 240px;

    background-image: url('/public/image/photo_dennis.jpg');
    background-repeat: no-repeat;
    background-size: contain;

    border-radius: 100%;
}

.content-about-profile-name
{
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;

    color: #ffffff;

    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.content-about-profile-title
{
    margin-left: auto;
    margin-right: auto;

    color: #ffffff;

    text-align: center;
    font-size: 18px;
    font-weight: 400;
}

.content-about-profile-quote-container
{
    display: flex;

    margin-top: 32px;
}

.content-about-profile-quote-icon
{
    min-width: 40px;
    margin-top: 6px;
    margin-right: 20px;

    background-image: url('/public/image/icon_quote.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-about-profile-text
{
    margin-right: 21px;

    text-align: left;
    font-style: italic;
    font-size: 18px;
    line-height: 24px;

    color: #ffffff;
}

.content-about-photo-carousel
{
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: -15px;
    margin-right: -15px;
}

.content-about-carousel-button-left
{
    background: #ffffff;
	color: inherit;
	border: none;
	font: inherit;
	outline: inherit;

    width: 60px;
    height: 60px;

    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.content-about-carousel-button-right
{
    background: #ffffff;
	color: inherit;
	border: none;
	font: inherit;
	outline: inherit;

    width: 60px;
    height: 60px;

    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.content-about-carousel-button-right:hover
{
    cursor: pointer;
}

.content-about-carousel-chevron-left
{
    min-width: 30px;
    min-height: 30px;
    padding: 15px;

    background-image: url('/public/image/icon_chevron_left.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.content-about-carousel-chevron-right
{
    min-width: 30px;
    min-height: 30px;
    padding: 15px;

    background-image: url('/public/image/icon_chevron_right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.content-about-photo-container
{
    height: 320px;

    text-align: center;
}

.content-about-photo
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;

    border-radius: 30px;
}

.content-about-sarajevo
{
    width: 100%;
    height: 480px;

    background-image: url('/public/image/photo_sarajevo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.content-about-sarajevo-container
{
    display: flex;
    align-items: flex-end;

    height: calc(480px - 7.8125vw);
}

.content-about-sarajevo-outro
{
    position: absolute;

    top: calc(480px + 72px - 7.8125vw + 5px);
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_about_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
}

.content-about-sarajevo-title-container
{
    display: flex;
    align-items: center;
}

.content-about-sarajevo-icon
{
    min-width: 64px;
    min-height: 80px;

    background-image: url('/public/image/icon_marker_white.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-about-sarajevo-title
{
    margin-left: 16px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    
    color: #ffffff;
    text-shadow: 0 10px 32px #000000c7;
}

.content-article-container
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-article-title-container
{
    margin-right: 32px;
}

.content-article-title-h1-mobile
{
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-article-title-h1-tablet
{
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: right;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-article-title-h1-desktop
{
    margin-bottom: 16px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    text-align: right;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-article-subtitle-mobile
{
    margin-bottom: 16px;

    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.content-article-subtitle-tablet
{
    margin-bottom: 16px;
    
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: right;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.content-article-subtitle-desktop
{
    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    text-align: right;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.content-article-photo
{
    border-radius: 30px;
}

.content-article-photo-text
{
    margin-bottom: 32px;

    border-radius: 30px;
}

.content-article-intro-text-container
{
    display: flex;
}

.content-article-intro-text-icon
{
    min-width: 64px;
    min-height: 95px;
    
    margin-top: 7px;
    margin-right: 16px;

    background-image: url('/public/image/icon_financeteams.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-article-intro-text-mobile
{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-article-intro-text-desktop
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-article-intro
{
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_howwework_intro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center top;
}

.content-article-outro
{
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_howwework_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center top;
}

.content-articleoverview-intro
{
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_articleoverview_intro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center bottom;
}

.content-articleoverview-outro
{
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_articleoverview_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center top;
}

.content-articleoverview-container
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-articleoverview-title-container
{
    margin-right: 32px;
}

.content-articleoverview-title-mobile
{
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-articleoverview-title-tablet
{
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: right;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-articleoverview-title-desktop
{
    margin-bottom: 16px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    text-align: right;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-articleoverview-subtitle-mobile
{
    margin-bottom: 24px;

    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.content-articleoverview-subtitle-tablet
{
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: right;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.content-articleoverview-subtitle-desktop
{
    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    text-align: right;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.content-articleoverview-photo
{
    border-radius: 30px;
}

/* Seperate animation div is required because of the Masonry */
.content-article-preview-animation
{
    padding-top: 32px;
    padding-bottom: 0px;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

.content-article-preview-animation:hover
{
    padding-top: 24px;
    padding-bottom: 8px;

    cursor: pointer;
}

.content-article-preview-container
{
    margin-top: 32px;
    margin-bottom: 0px;
    
    border-radius: 30px;
}

.content-article-preview-photo
{
    width: 100%;
    height: 236px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.content-article-preview-bottom
{
    padding: 30px;
    
    color: #ffffff;
    background-color: #738ccb;

    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.content-article-preview2-bottom
{
    padding: 30px;

    color: #ffffff;
    background-color: #87a0da;

    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.content-article-preview-title-mobile
{
    margin-bottom: 12px;

    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-article-preview-title-desktop
{
    margin-bottom: 12px;

    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-article-preview2-title-mobile
{
    margin-bottom: 12px;

    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-article-preview2-title-desktop
{
    margin-bottom: 12px;

    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-article-preview-date
{
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-article-preview2-date
{
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-article-preview-button-container
{
    display: flex;
}

.content-article-preview-button
{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    
    color: #ffffff;
    background-color: #4462ae;

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;

    border-color: #4462ae;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;

    transition: all .25s ease-out;
}

.content-about-profile-outro
{
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_profile_about_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center top;
}

.content-article-quote-container
{
    display: flex;

    margin-top: 32px;
    margin-bottom: 32px;
}

.content-article-quote
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    text-align: left;
    font-style: italic;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.content-article-quote-icon
{
    min-width: 40px;
    margin-top: 7px;
    margin-right: 16px;

    background-image: url('/public/image/icon_quote.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-articlequote-intro
{
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_articlequotes_intro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center bottom;
}

.content-articlequote-outro
{
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_articlequotes_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center top;
}

.content-articlequote-title-mobile
{
    margin-bottom: 40px;

    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-articlequote-title-desktop
{
    margin-bottom: 40px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

/* Seperate animation div is required because of the Masonry */
.content-articlequote-animation
{
    padding-top: 32px;
    padding-bottom: 0px;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

.content-articlequote-animation:hover
{
    padding-top: 24px;
    padding-bottom: 8px;

    cursor: pointer;
}

.content-articlequote-container
{
    margin-top: 32px;
    margin-bottom: 0px;
    padding: 30px;

    color: #ffffff;
    background-color: #738ccb;
    
    border-radius: 30px;
}

.content-articlequote-photo-container
{
    margin-bottom: 16px;
    width: 100%;
}

.content-articlequote-photo
{
    margin-left: auto;
    margin-right: auto;
    width: 160px;
    height: 160px;
    
    background-repeat: no-repeat;
    background-size: contain;

    border-radius: 80px;
}

.content-articlequote-name
{
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.content-articlequote-quote-container
{
    display: flex;

    margin-bottom: 16px;
}

.content-articlequote-quote-icon
{
    min-width: 30px;
    margin-top: 6px;
    margin-right: 12px;

    background-image: url('/public/image/icon_quote.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-articlequote-quote
{
    text-align: left;
    font-style: italic;
}

.content-articlequote-button-container
{
    display: flex;
    width: 100%;
}

.content-articlequote-button
{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    
    color: #ffffff;
    background-color: #4462ae;

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;

    border-color: #4462ae;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;

    transition: all .25s ease-out;
}

.content-articlequote-container:hover .content-articlequote-button
{
    color: #ffffff;
    background-color: #738ccb;

    text-decoration: none;
}

.content-form-section
{
    padding-top: 48px;
    padding-bottom: 48px;

    background-color: #ffffff;
}

.content-form-error
{
    color: #d73030;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    text-align: left;
}

.content-form-header
{
    margin-bottom: 24px;
}

.content-form-singleline
{
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 5px;

    width: 100%;
    height: 44px;

    color: #212529;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    border: none;
    border-bottom: 1px solid #c3c3c3;
}

.content-form-singleline-dropdown
{
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 5px;

    width: 100%;
    height: 44px;

    color: #212529;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;

    border: none;
    border-bottom: 1px solid #c3c3c3;
    border-radius: 0;
    
    background-color: #ffffff;
    background-image: url('/public/image/icon_dropdown.svg');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: right 12px top 50%;
}

.content-form-multiline
{
    margin-top: 15px;
    margin-bottom: 5px;
    padding-top: 15px;
    padding-bottom: 0px;
    
    width: 100%;
    min-height: 86px;
    
    color: #212529;
    
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;

    border: none;
    border-bottom: 1px solid #c3c3c3;
}

.content-form-checkbox-container
{
    margin-top: 15px;

    color: #212529;

    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    text-align: left;
}

.content-form-checkbox input
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.content-form-checkbox
{
    position: relative;
    display: block;
    margin-top: 20px;
}

.content-form-checkbox span
{
    display: block;
    margin-left: 30px;

    text-align: left;
}

.content-form-checkbox span::before
{
    content: "";
    display: block;
    position: absolute;

    width: 20px;
    height: 20px;
    left: 0;
    top: 0;

    background: #ffffff;

    border: 1px solid #c3c3c3;
    border-radius: 6px;
}

.content-form-checkbox:hover
{
    cursor: pointer;
}

.content-form-checkbox-active input
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.content-form-checkbox-active
{
    position: relative;
    display: block;
    margin-top: 20px;
}

.content-form-checkbox-active span
{
    display: block;
    margin-left: 30px;

    text-align: left;
}
  
.content-form-checkbox-active span::before
{
    content: "";
    display: block;
    position: absolute;

    width: 20px;
    height: 20px;
    left: 0;
    top: 0;

    background: #4462ae;

    border: 2px solid #4462ae;
    border-radius: 6px;
}

.content-form-checkbox-active:hover
{
    cursor: pointer;
}

.content-form-title-mobile
{
    margin: 0;
    margin-top: 0px;
    margin-bottom: 16px;

    font-size: 32px;
    line-height: 38px;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    
    color: #000000;
}

.content-form-title-desktop
{
    margin: 0;
    margin-top: 0px;
    margin-bottom: 48px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    
    color: #000000;
}

.content-form-text
{   
    margin-bottom: 8px;

    color: #212529;

    text-decoration: none;
    font-size: 16px;
    line-height: 19px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.content-form-accept
{
    display: none;
}

.content-form-send
{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 24px;
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    
    color: #ffffff;
    background-color: #4462ae;

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;

    border-color: #4462ae;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;

    transition: all .25s ease-out;
}

.content-form-send:hover
{
    color: #4462ae;
    background-color: #ffffff;

    text-decoration: none;
    
    cursor: pointer;
}

.content-form-send-icon
{
    display: inline-block;

    margin-left: 12px;
    width: 24px;
    height: 24px;

    background-image: url('/public/image/icon_send.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-form-send:hover .content-form-send-icon
{
    background-image: url('/public/image/icon_send_blue.svg');
}

.content-form-thankyou
{
    margin-top: 12px;
    text-align: left;
}

.content-form-loading
{
    width: 48px;
    height: 48px;

    background-image: url('/public/image/icon_loading.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 48px 48px;

    animation-name: form-loading-spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes form-loading-spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.content-howwework-office
{
    width: 100%;
    height: 480px;

    background-image: url('/public/image/photo_desk.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.content-howwework-office-container
{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    height: calc(480px - 7.8125vw);
}

.content-howwework-office-outro
{
    position: absolute;

    top: calc(480px + 72px - 7.8125vw + 5px);
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_about_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
}

.content-howwework-office-title
{
    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    
    color: #ffffff;
    text-shadow: 0 10px 32px #000000c7;
}

.content-howwework-curves-container
{
    margin-top: 48px;
}

.content-howwework-curves
{
    display: table;

    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;

    vertical-align: middle;
}

.content-howwework-curves-icon-container
{
    display: table-cell;

    vertical-align: middle;
}

.content-howwework-curves-icon-needs
{
    display: inline-block;

    min-width: 72px;
    min-height: 72px;
    margin-right: 32px;

    background-image: url('/public/image/icon_needs.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.content-howwework-curves-icon-meeting
{
    display: inline-block;

    min-width: 72px;
    min-height: 72px;
    margin-right: 32px;

    background-image: url('/public/image/icon_meeting.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.content-howwework-curves-icon-partnership
{
    display: inline-block;

    min-width: 72px;
    min-height: 72px;
    margin-right: 32px;

    background-image: url('/public/image/icon_partnership.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.content-howwework-curves-icon-selection
{
    display: inline-block;

    min-width: 72px;
    min-height: 72px;
    margin-right: 32px;

    background-image: url('/public/image/icon_selection.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.content-howwework-curves-title
{
    display: inline-block;

    margin-bottom: 12px;

    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-howwework-curves-text
{
    margin-bottom: 0px;

    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-howwework-curve
{
    margin: 0 auto;
    margin-top: 12px;
    margin-bottom: 12px;

    height: 128px;

    background-image: url('/public/image/vector_curve.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    
}

.content-howwework-curve-mirror
{
    margin: 0 auto;

    width: 320px;
    height: 128px;

    background-image: url('/public/image/vector_curve_mirror.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.content-howwework-intro
{
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_howwework_intro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center bottom;
}

.content-howwework-outro
{
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_howwework_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center top;
}

.content-howwework-title-mobile
{
    margin-top: 0px;
    margin-bottom: 12px;

    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-howwework-title-desktop
{
    margin-top: 0px;
    margin-bottom: 12px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-howwework-subtitle-mobile
{
    margin-bottom: 12px;

    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-howwework-subtitle-desktop
{
    margin-bottom: 12px;

    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    margin-top: 12px;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-howwework-quote-container
{
    display: flex;

    margin-top: 32px;
}

.content-howwework-quote
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    text-align: left;
    font-style: italic;
    -webkit-font-smoothing: antialiased;

    color: #212529;
}

.content-howwework-quote-icon
{
    min-width: 40px;
    margin-top: 7px;
    margin-right: 16px;

    background-image: url('/public/image/icon_quote.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-howwework-text
{
    margin-top: 48px;

    color: #212529;
}

.content-howwework-container
{
    margin-bottom: 16px;
}

.content-howwework-item-container
{
    margin-top: 32px;
}

.content-howwework-item-title-container
{
    display: flex;
    align-items: center;

    margin-bottom: 16px;
}

.content-howwework-item-title
{
    margin-left: 12px;

    color: #ffffff;

    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

.content-howwework-item-text
{
    color: #ffffff;
}

.content-howwework-item-icon-stack
{
    min-width: 30px;
    min-height: 30px;

    background-image: url('/public/image/icon_stack.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-howwework-item-icon-travel
{
    min-width: 30px;
    min-height: 30px;

    background-image: url('/public/image/icon_travel.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-onboarding-text
{
    margin-top: 24px;
    margin-bottom: 32px;
    padding-right: 24px;
}

.content-onboarding-button-container
{
    display: flex;
    justify-content: center;
}

.content-onboarding-button
{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    
    color: #ffffff;
    background-color: #4462ae;

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;

    border-color: #4462ae;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;

    transition: all .25s ease-out;
}

.content-onboarding-button:hover
{
    color: #4462ae;
    background-color: #ffffff;

    text-decoration: none;
    
    cursor: pointer;
}

.content-meettheteam-intro
{
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_meettheteam_intro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center bottom;
}

.content-meettheteam-outro
{
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 7.8125vw;

    background-image: url('/public/image/vector_meettheteam_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 7.8125vw;
    background-position: center top;
}

.content-meettheteam-title-mobile
{
    margin-bottom: 12px;

    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-meettheteam-title-desktop
{
    margin-bottom: 12px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-meettheteam-subtitle-mobile
{
    margin-bottom: 12px;

    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-meettheteam-subtitle-desktop
{
    margin-bottom: 12px;

    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    margin-top: 12px;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-meettheteam-text
{
    margin-top: 24px;

    color: #ffffff;
}

.content-meettheteam-button-container
{
    display: flex;
    justify-content: center;
}

.content-meettheteam-button
{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    
    color: #ffffff;
    background-color: #4462ae;

    font-size: 18px;
    font-weight: 400;
    text-decoration: none;

    border-color: #4462ae;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;

    transition: all .25s ease-out;
}

.content-meettheteam-button:hover
{
    color: #ffffff;
    background-color: #87a0da;

    text-decoration: none;
    
    cursor: pointer;
}

.content-photo-container
{
    margin-top: 24px;
}

.content-photo
{
    margin-top: 24px;

    border-radius: 30px;
}

.content-photo-nomargin
{
    border-radius: 30px;
}

.content-section-about
{
    padding-top: 24px;
    padding-bottom: 24px;
}

.content-section-about-profile
{
    padding-top: 64px;
    padding-bottom: 64px;

    background-color: #738ccb;
}

.content-section-article-banner
{
    padding-top: 64px;
    padding-bottom: 32px;
}

.content-section-article-intro
{
    padding-top: 32px;
    padding-bottom: 32px;

    background-color: #738ccb;
}

.content-section-articleoverview-banner
{
    padding-top: 64px;
    padding-bottom: 64px;
}

.content-section-articleoverview
{
    padding-top: 64px;
    padding-bottom: 96px;

    background-color: #87a0da;
}

.content-section-articlequotes
{
    padding-top: 64px;
    padding-bottom: 96px;

    background-color: #87a0da;
}

.content-section-howwework
{
    padding-top: 24px;
    padding-bottom: 24px;
}

.content-section-howwework2
{
    padding-top: 24px;
    padding-bottom: 24px;

    background-color: #738ccb;
}

.content-section-meettheteam
{
    padding-top: 64px;
    padding-bottom: 64px;

    background-color: #738ccb;
}

.content-section-onboarding
{
    padding-top: 64px;
    padding-bottom: 48px;
}

.content-section-profiles
{
    padding-top: 48px;
    padding-bottom: 24px;
}

.content-section-standard
{
    padding-top: 64px;
    padding-bottom: 24px;
}

.content-section-vacancies-list
{
    padding-bottom: 64px;
}

.content-section-vacancies
{
    padding-top: 24px;
    padding-bottom: 64px;

    background-color: #738ccb;
}

.content-section-vacancy
{
    padding-top: 48px;
    
    margin-bottom: -48px;
}

.content-section-video
{
    padding-top: 64px;
    padding-bottom: 24px;
}

.content-section-whatwedo
{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 32px;
}

.content-title-h1-mobile
{
    margin-bottom: 16px;

    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-title-h1-desktop
{
    margin-bottom: 24px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-title-h2-mobile
{
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    
    color: #000000;
}

.content-title-h2-desktop
{
    margin-top: 0px;
    margin-bottom: 24px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-title-h2-small-mobile
{
    margin-top: 0px;

    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    
    color: #000000;
}

.content-title-h2-small-desktop
{
    margin-top: 0px;
    margin-bottom: 24px;

    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;

    color: #000000;
}

.content-vacancies-item-container
{
    margin-top: 32px;
}

.content-vacancies-item-title
{
    margin-bottom: 16px;

    color: #000000;

    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

.content-vacancies-item-text
{
    color: #212529;
}

.content-vacancies-intro
{
    margin-bottom: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 11vw;

    background-image: url('/public/image/vector_vacancies_intro.svg');
    background-repeat: no-repeat;
    background-size: 100% 11vw;
    background-position: center bottom;
}

.content-vacancies-outro
{
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    height: 11vw;

    background-image: url('/public/image/vector_vacancies_outro.svg');
    background-repeat: no-repeat;
    background-size: 100% 11vw;
    background-position: center top;
}

.content-vacancies-overview-title-mobile
{
    margin-bottom: 12px;

    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-vacancies-overview-title-desktop
{
    margin-bottom: 12px;

    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;
}

.content-vacancies-container
{
    margin-top: 32px;
    margin-bottom: 0px;

    color: #000000;
    background-color: #ffffff;
    
    border-radius: 30px;
    border-bottom-width: 0px;

    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

.content-vacancies-container:hover
{
    margin-top: 24px;
    margin-bottom: 8px;

    cursor: pointer;
}

.content-vacancies-top
{
    padding: 30px;
    padding-bottom: 14px;
}

.content-vacancies-bottom
{
    padding: 30px;
    padding-top: 15px;

    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: #738ccb;
}

.content-vacancies-title
{
    margin-bottom: 12px;

    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}

.content-vacancies-text
{
    text-align: left;
}

.content-vacancies-icons-container
{
    display: flex;
    align-items: top;
    margin-top: 15px;
}

.content-vacancies-icons-title
{
    margin-left: 12px;

    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

.content-vacancies-icon-clock
{
    min-width: 30px;
    min-height: 30px;

    background-image: url('/public/image/icon_clock.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-vacancies-icon-education
{
    min-width: 34px;
    min-height: 24px;

    background-image: url('/public/image/icon_education.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-vacancies-icon-marker
{
    min-width: 30px;
    max-height: 34px;

    background-image: url('/public/image/icon_marker.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-vacancies-icon-schedule
{
    min-width: 30px;
    min-height: 30px;

    background-image: url('/public/image/icon_schedule.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-vacancies-carousel
{
    margin-top: 60px;
    margin-left: -15px;
    margin-right: -15px;
}

.content-vacancies-carousel-button-left
{
    background: #ffffff;
	color: inherit;
	border: none;
	font: inherit;
	outline: inherit;

    width: 60px;
    height: 60px;

    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.content-vacancies-carousel-button-right
{
    background: #ffffff;
	color: inherit;
	border: none;
	font: inherit;
	outline: inherit;

    width: 60px;
    height: 60px;

    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.content-vacancies-carousel-button-right:hover
{
    cursor: pointer;
}

.content-vacancies-carousel-chevron-left
{
    min-width: 30px;
    min-height: 30px;
    padding: 15px;

    background-image: url('/public/image/icon_chevron_left.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.content-vacancies-carousel-chevron-right
{
    min-width: 30px;
    min-height: 30px;
    padding: 15px;

    background-image: url('/public/image/icon_chevron_right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.content-vacancies-photo-container
{
    height: 320px;

    text-align: center;
}

.content-vacancies-photo
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;

    border-radius: 30px;
}

.content-vacancy-language
{
    margin-bottom: 32px;
}

.content-vacancy-language-flag-en
{
    display: inline-block;

    width: 18px;
    height: 12px;
    margin-right: 5px;
    margin-bottom: -1px;

    background-image: url('/public/image/icon_flag_en.svg');
    background-repeat: no-repeat;
    background-size: 18px 11px;
}

.content-vacancy-title
{
    margin: 0;
    margin-top: 0px;
    margin-bottom: 12px;

    font-size: 54px;
    line-height: 60px;
    
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    
    color: #000000;
}

.content-vacancy-container
{
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 32px;

    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #738ccb;
}

.content-vacancy-text
{
    margin-bottom: 32px;
}

.content-whatwedo-item-container
{
    margin-bottom: 32px;
}

.content-whatwedo-item-title-container
{
    display: flex;
    align-items: center;

    margin-bottom: 8px;
}

.content-whatwedo-item-title
{
    margin-left: 12px;

    color: #000000;

    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

.content-whatwedo-item-text
{
    color: #212529;
}

.content-whatwedo-item-icon-growth
{
    min-width: 30px;
    min-height: 30px;

    background-image: url('/public/image/icon_growth.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-whatwedo-item-icon-checkmarkcircle
{
    min-width: 30px;
    min-height: 30px;

    background-image: url('/public/image/icon_checkmarkcircle.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.content-whatwedo-item-icon-heart
{
    min-width: 30px;
    min-height: 30px;

    background-image: url('/public/image/icon_heart.svg');
    background-repeat: no-repeat;
    background-size: contain;
}